@use 'custom-properties';
@use 'semantic-variables';
@use 'sportsbook';

// base/type

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
label,
legend,
button,
input,
select,
textarea,
li,
a,
strong,
span,
th,
caption,
td,
p,
footer,
small {
	color: rgba(var(--font-light), 1);
	font-family: 'Exo 2', sans-serif;
}

body {
	background-color: rgba(var(--base-12), 1);

	&.access-blocked {
		background-color: rgba(var(--primary-accent-grey-dark), 1);

		h2 {
			color: rgba(var(--font-light), 1);
			font: var(--h2);
		}

		p {
			color: rgba(var(--font-supportive), 1);
		}

		a {
			color: rgba(var(--primary-accent-green), 1);
		}
	}
}

small {
	color: rgba(var(--font-supportive), 1);
}

// components/bonus-active

.progress {
	svg {
		path {
			stroke: rgba(var(--base-09), 1);
		}

		.progress__meter {
			stroke: rgba(var(--primary-accent-green), 1);
		}
	}
}

// components/buttons

.btn {
	background-color: rgba(var(--primary-accent-green), 1);
	border-radius: var(--button-border-radius);
	color: rgba(var(--base-12), 1);

	&:disabled {
		background-color: rgba(var(--base-09), 1);
		color: rgba(var(--base-12), 1);
	}

	&.btn--secondary {
		background-color: rgba(var(--base-12), 1);
		border-radius: 3px;
		color: rgba(var(--base-02), 1);
	}

	&.btn--tertiary,
	&.btn--ghost {
		background: none;
		color: rgba(var(--base-02), 1);
	}

	&.btn--clear,
	&.btn--login,
	&.btn--signup,
	&.btn--skip {
		color: rgba(var(--primary-accent-green), 1);
	}

	&.btn--applepay {
		-apple-pay-button-style: --base-01;
	}

	&.btn--light {
		background-color: rgba(var(--primary-accent-green), 1);
		color: rgba(var(--base-12), 1);

		&:hover {
			@media (hover: hover) {
				background: rgba(var(--primary-accent-green-light), 1);
				color: rgba(var(--font-dark), 1);
			}
		}
	}

	&.btn--link {
		color: rgba(var(--font-brand-01), 1);
	}

	&.btn--small {
		color: rgba(var(--base-12), 1);
	}

	&.btn--visit {
		background-color: rgba(var(--base-10), 1);
		color: rgba(var(--base-01), 1);
	}

	&.btn--product-switch {
		color: rgba(var(--base-02), 1);
	}

	&.btn--small.btn--clip-card {
		background-color: transparent;
		border: 1px solid rgba(var(--primary-accent-green), 1);
		color: rgba(var(--primary-accent-green), 1);

		&:hover {
			@media (hover: hover) {
				background: rgba(var(--primary-accent-green), 1);
				border: 1px solid rgba(var(--primary-accent-green), 1);
				color: rgba(var(--font-dark), 1);
			}
		}
	}

	&.btn--tutorial-card-button {
		background-color: transparent;
		block-size: fit-content;
		border: 0;
		color: rgba(var(--primary-accent-green), 1);
		margin: 14px 0;
		padding: 0;
		text-decoration: underline;
		width: fit-content !important;
	}

	&.btn--action-screen-confirm {
		background-color: rgba(var(--primary-accent-green), 1);
		color: rgba(var(--font-dark), 1);
	}

	&.btn--responsible-gaming-logout {
		background-color: transparent;
		color: rgba(var(--primary-accent-green-light), 1);
	}

	&.btn--transaction-history-reality-check,
	&.btn--disable-reality-check {
		background-color: transparent;
		border: solid 1px rgba(var(--primary-accent-green), 1);
		color: rgba(var(--primary-accent-green), 1);
	}
}

// components/cc-form

.cc-form,
li.deposit-limit {
	&:focus-within .active-border {
		border-bottom: 2px solid rgba(var(--primary), 1);
	}
}

// components/detail-accordion

.details-accordion {
	.summary-icon::after {
		filter: brightness(97%) sepia(0) hue-rotate(0) saturate(0);
	}
}

// components/filters

.user-options {
	background-color: rgba(var(--base-10), 1);
	border-bottom: 1px solid rgba(var(--base-12), 1);
}

.filters {
	input {
		+ .filters__filter {
			background-color: rgba(var(--base-10), 1);
			color: rgba(var(--font-light), 1);
		}

		&:checked {
			+ .filters__filter {
				color: rgba(var(--primary-accent-green), 1);
			}
		}
	}

	.sliding-border {
		background-color: rgba(var(--primary-accent-green), 1);
		height: 2px;
	}
}

.shop-product-filters {
	background-image: var(--search-input-wrapper-mobile-bg) !important;
	background-position: left -44px;
	background-size: 216px 222px;

	a {
		&::after {
			background-color: var(--filter-bar-inactive);
			bottom: 0;
			content: '';
			height: 1px;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}
}

// components/flags
// components/form

.form {
	input[type='text'],
	input[type='date'],
	input[type='time'],
	input[type='number'],
	select,
	.custom-select select {
		background-color: rgba(var(--base-11), 1);
		border-bottom: 1px solid rgba(var(--primary-accent-grey-light), 1);
		color: rgba(var(--base-01));

		+ label,
		+ legend {
			color: rgba(var(--base-06), 1);

			&.error {
				color: rgba(var(--error), 1);
			}
		}

		&:focus,
		&:hover {
			~ .form__border {
				background-color: rgba(var(--primary-accent-green), 1);
			}
		}

		&.input-amount {
			+ label {
				color: rgba(var(--base-01), 1);
			}
		}
	}

	input[type='text']:not(:placeholder-shown) + label {
		color: rgba(var(--font-supportive), 1);
	}

	.form__border {
		&.form__border--error {
			background-color: rgba(var(--error), 1);
		}
	}

	.error {
		color: rgba(var(--error));
	}
}

::-webkit-calendar-picker-indicator {
	filter: invert(1);
}

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
	box-shadow: inset 0 0 0 9999px #1e2021 !important;
	-webkit-text-fill-color: #fff !important;
}

.input-wrap {
	.input-wrap__input {
		border-bottom: 1px solid rgba(var(--base-08), 1);
		color: rgba(var(--base-01), 1);
		transition: color ease 300ms;

		&:focus {
			color: rgba(var(--primary-accent-green), 1);
		}
	}

	&::after {
		background-color: rgba(var(--primary-accent-green), 1);
	}

	.slider-input {
		background-color: rgba(var(--base-12), 1);
	}
}

.slider-icon {
	.icon--sprite {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

.legend,
.input-wrap__label {
	color: rgba(var(--base-07), 1);
}

::-webkit-input-placeholder {
	// color: rgba(var(--font-light), 1);
	color: rgba(var(--font-supportive), 1);
}

::-moz-placeholder {
	// color: rgba(var(--font-light), 1);
	color: rgba(var(--font-supportive), 1);
}

:-ms-input-placeholder {
	// color: rgba(var(--font-light), 1);
	color: rgba(var(--font-supportive), 1);
}

// New checkboxes

.custom-checkbox-list {
	li {
		border-bottom: 1px solid rgba(var(--base-08), 1);
	}
}

.toggle-password {
	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

// components/game-category
// components/game-item
// components/game-list

.item__footer {
	border-top: 1px solid rgba(var(--base-12), 1);

	.item__love {
		border-right: 1px solid rgba(var(--base-12), 1);

		.item__love-icon {
			fill: rgba(var(--base-02), 1);
		}
	}
}

.item__love.game-item__love--selected {
	svg.item__love-icon {
		fill: rgba(var(--primary-accent-green), 1);
	}

	.item__love-counter {
		color: rgba(var(--primary-accent-green), 1);
	}
}

.item__live-casino {
	border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);
	color: rgba(var(--base-07), 1);
}

.game-item__jackpot {
	background-color: rgba(var(--base-08), 1);
	color: rgba(var(--base-02), 1);
}

// components/header

.page-head {
	background: var(--sidebar-bg);

	&.country-select-header {
		.page-head__title {
			color: rgba(var(--base-07), 1);
		}

		svg {
			fill: rgba(var(--base-07), 1);
		}
	}

	&.page-head--we-spin {
		background-color: var(--sidebar-bg);

		.page-head__title,
		.page-head__title span {
			color: rgba(var(--font-light), 1);
		}

		.page-head__right-side p {
			color: rgba(var(--primary-accent-green), 1);
		}

		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}
}

.page-head__left-side {
	.icon--sprite {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

// components/german regulations header
.reg-header-wrapper {
	.swipe-button-container {
		background: rgba(var(--info-light), 1);
	}

	.swipe-button-slider {
		background: rgba(var(--info), 1);
	}

	.panic-btn_icon {
		background: rgba(var(--info), 1);
		color: rgba(var(--base-01), 1);
	}

	.panic-btn_txt {
		color: rgba(var(--base-12), 1);
	}
}

// components/german regulations header panic-reason

.account-panic-logout {
	.panic-reason-desc {
		color: rgba(var(--base-06), 1);
	}

	.panic-reason-select {
		color: rgba(var(--base-03), 1);
	}
}

// components/link-list

.link-list {
	.link-list__item {
		background-color: rgba(var(--primary-accent-grey-dark), 1);

		&--rounded {
			border-radius: 3px;
		}

		&.disabled {
			svg {
				fill: rgba(var(--base-08), 1);
			}

			.menu__main-title,
			.link-list__preamble,
			.link-list__continue-icon {
				color: rgba(var(--base-08), 1);
			}
		}

		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}

		.menu__main-title,
		.link-list__preamble {
			color: rgba(var(--base-02), 1);
		}

		.link-list__continue-icon {
			fill: rgba(var(--primary-accent-green), 1);
		}

		.link-list__more-icon {
			height: 20px;
			width: 20px;
		}

		.link-list__value {
			color: rgba(var(--base-07), 1);
		}

		.link-list__status-verified {
			color: rgba(var(--primary-accent-green), 1);
		}

		.link-list__status-unverified {
			color: rgba(var(--base-07), 1);
		}
	}
}

// components/list-options

.list-options {
	background-color: rgba(var(--base-10), 1);

	.link-list {
		.link-list__item {
			background-color: transparent;
			border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);

			.link-list__continue-icon {
				fill: rgba(var(--primary-accent-grey), 1);
			}
		}
	}
}

// components/my notifications

.cta-button-container {
	background: rgba(var(--base-11), 1);
}

// components/

.notification-wrap {
	box-shadow: 0 0 4px black;
	color: rgba(var(--base-02), 1);

	.page-head__close {
		svg {
			fill: #fff;
		}
	}

	p,
	.notification-wrap__error-text-msg {
		color: rgba(var(--base-02), 1);
	}

	.notification-wrap__error-text-header {
		color: rgba(var(--base-02), 1);
	}

	.header-icon {
		svg {
			fill: #fff;
		}
	}

	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}

//notification - inline
.notification-inline-wrap {
	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}

// NOTIFY

.notify {
	&.notify--is-open {
		section {
			svg {
				fill: rgba(var(--base-02), 1);
			}

			h2,
			p {
				color: rgba(var(--base-02), 1);
			}
		}
	}

	&.notify--success {
		background-color: rgba(var(--success), 1) !important;
	}

	&.notify--failure {
		background-color: rgba(var(--error), 1) !important;
	}
}

.notify__head {
	.notify__head--quick-deposit {
		fill: var(--section-head-main-icon);
	}
}

// PROGRESS BAR

.notify__progress-bar {
	background-color: rgba(var(--base-11), 1);

	&.notify__progress-bar--fill-success {
		background-color: rgba(var(--success), 1);
		background-image: none;
	}

	&.notify__progress-bar--fill-fail {
		background-color: rgba(var(--error), 1);
		background-image: none;
	}
}

// OPTION BOX
// OVERLAY BEHIND MODAL

// Binary question modal
.binary-question {
	background-color: rgba(var(--base-10), 1);

	&.notify--is-open {
		background-color: rgba(var(--base-11), 0.8);
	}

	.binary-question__inner {
		background-color: rgba(var(--base-09), 1);
	}

	.input-wrap > .input-wrap__input,
	.two-fact__input-wrap input {
		background-color: rgba(var(--base-10), 1);
	}

	.binary-question__yes,
	.binary-question__no,
	.binary-question__third {
		background-color: rgba(var(--base-1), 1);
		border-radius: 3px 0 0 3px;
		border-top: 1px solid rgba(var(--base-11), 1);

		&:disabled {
			color: rgba(var(--base-08), 1);
		}
	}

	.binary-question__yes {
		border-left: 1px solid rgba(var(--base-11), 1);
		border-radius: 0 3px 3px 0;
		border-top: 1px solid rgba(var(--base-11), 1);
	}
}

// COOKIE CONSENT
// PAYMENT METHODS

.payment-methods {
	&__item {
		background-color: rgba(var(--primary-accent-grey-dark), 1);

		&__logo {
			svg.logo-payment-method {
				background-color: #fff;
			}
		}

		&__chevron {
			svg.link-list__continue-icon {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}
}

.js-notify__button-wrapper--failed {
	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

.bonus-step__hydration-spinner-bg-color {
	background-color: rgba(var(--base-10), 1);
}

.payment-reminder {
	color: rgba(var(--base-01));
	padding: 6px 0;
	white-space: pre-line;
}

// Two Factor auth on execute deposit

.two-fact {
	&__error-label {
		color: rgba(var(--error), 1);
	}

	.btn:disabled {
		background-color: inherit;
	}
}

.notify--is-open {
	background-color: rgba(var(--base-10), 1);

	&.reality-check-confirm {
		background-color: rgba(var(--primary-accent-grey), 1) !important;
		background-image: none;
	}
}

.option-box {
	background-color: rgba(var(--base-10), 0.8);

	.option-box__inner {
		border-bottom: 1px solid rgba(var(--base-12), 1);
	}

	.btn--secondary {
		background-color: rgba(var(--base-12), 1);
	}

	.user-options {
		.filters {
			input {
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				+ .filters__filter {
					background-color: rgba(var(--base-09), 1);
				}
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				&:checked {
					//TODO: fix this, reduce nesting REV-318
					//stylelint-disable-next-line selector-max-compound-selectors
					+ .filters__filter {
						background-color: rgba(var(--primary-accent-green), 1);
					}
				}
			}
		}
	}
}

// components/quick-deposit

.quick-deposit-wrapper {
	background-color: rgba(var(--base-10), 1);
}

.quick-deposit-response {
	.page-head {
		border-bottom: 0;
	}

	&.quick-deposit-response--pending {
		background-color: rgba(var(--base-10), 1);
	}
}

// components/scroll-page-head

header.page-head {
	&.page-head--opac {
		background-color: rgba(var(--base-10), 1);

		.page-head__title {
			color: rgba(var(--base-02), 1);
		}
	}
}

// components/select

.custom-select {
	border-bottom: 1px solid rgba(var(--base-08), 1);

	select {
		background-color: rgba(var(--base-11), 1);
		color: rgba(var(--base-01), 1) !important;
	}

	option {
		color: rgba(var(--base-01), 1);
	}

	select.placeholder-active {
		color: rgba(var(--base-07), 1) !important;
	}

	&::after {
		filter: invert(50%) sepia(78%) saturate(1152%) hue-rotate(55deg) brightness(100%) contrast(98%);
	}
}

// components/spinner
// components/support-nav

.dots-container {
	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

.support-nav.support-nav--is-open {
	background-color: rgba(var(--opacity-overlay), 0.85);

	.btn--secondary {
		background-color: rgba(var(--propose-locale-head-bg), 1);
	}
}

// components/proposed-locale-nav

.propose-locale-nav {
	.propose-locale-wrapper {
		border-radius: 3px;

		.btn--secondary {
			border-bottom: 0;
			border-top: 1px solid rgba(var(--propose-locale-border, var(--base-07)), 1);
		}
	}
}

// components/tables

td {
	border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);

	&.u-text-r p {
		color: rgba(var(--font-supportive), 1);
	}

	&:first-of-type {
		font: var(--label-bold);
	}

	&:last-of-type {
		color: rgba(var(--font-supportive), 1);
	}
}

// components/tabs

.tab {
	* {
		color: rgba(var(--font-supportive), 1);
	}

	[aria-selected='true'] {
		span {
			color: rgba(var(--primary-accent-green), 1);
		}

		svg {
			fill: rgba(var(--base-01), 1);
		}
	}
}

// components/textarea

.form {
	fieldset {
		.grow-wrap:focus-within {
			~ .form__border {
				background-color: rgba(var(--primary-accent-green), 1);
			}
		}
	}

	textarea {
		background-color: rgba(var(--base-11), 1);
		color: rgba(var(--base-01), 1);

		+ label,
		+ legend {
			color: rgba(var(--base-06), 1);
		}
	}

	textarea:not(:placeholder-shown) + label {
		color: rgba(var(--font-supportive), 1);
	}

	.form__border {
		&:focus-within &.form__border--error {
			background-color: rgba(var(--error), 1);
		}
	}

	.error {
		color: rgba(var(--error));
	}
}

.custom-select {
	&::after {
		right: 4px;
	}

	.js-select-box {
		padding: 20px 0 15px;
	}

	.custom-select--label {
		color: rgba(var(--font-supportive), 1);
	}
}

// Autosize textarea
.grow-wrap {
	background-color: rgba(var(--base-11), 1);
	border-bottom: 1px solid rgba(var(--base-06), 1);

	textarea,
	::after {
		color: rgba(var(--base-01));
	}
}

// Layouts Account
.account-icon-header .icon--sprite {
	fill: rgba(var(--base-01), 1) !important;
}

//layouts/bonus terms & condition
.bonus-terms-wrapper {
	.terms-header__text-color {
		color: rgba(var(--base-01), 1);
	}
}

// components/winners
// layouts/bundles
// layouts/deposit

.bonus-item__image-wrapper {
	background-image: var(--brandContainerBackground);
	background-size: 216px 222px;
}

// layouts/footer

.paragraph-footer,
.footer-legal-content {
	color: rgba(var(--footer-text), 1);

	a {
		color: rgba(var(--primary-accent-green), 1);
	}
}

.footer-legal-content small {
	color: rgba(var(--font-supportive), 1);
}

.payment-logos-footer {
	svg {
		fill: rgba(var(--base-07), 1);
	}
}

footer svg.footer-logo {
	fill: rgba(var(--footer-logo-color), 1);
}

.other-logos-footer {
	&.not-payment-logos-footer li {
		background-color: transparent;
	}
}

// layouts/info-box

.info-box {
	background-color: rgba(var(--base-12), 1);

	.info-box-text,
	.info-box-value {
		color: rgba(var(--base-09), 1);
	}
}

// layouts/log-in
// layouts/modal-view

.modal-view {
	background-color: rgba(var(--base-10), 1);

	.page-head {
		background-color: inherit;
	}

	&.modal-view--verified {
		background-color: rgba(var(--success), 1);
	}
}

.missing-data-dialog,
.sportsbook-relieve-limits-dialog,
.time-limits-dialog {
	background-color: rgba(var(--base-10), 1);

	.btn--skip {
		color: rgba(var(--base-02), 1);
	}
}

// layouts/my-limits

.my-limits-menu {
	.limits-notification-wrapper {
		.limits-notification-icon {
			fill: rgba(var(--primary-accent-green), 1);
		}

		.icon-close--small {
			.icon--sprite {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}

	.accordion {
		.accordion__link {
			background-color: rgba(var(--base-12), 1);
		}

		.accordion__content {
			background-color: rgba(var(--base-12), 1);
		}
	}
}

// layouts/off-canvas

.global-menu,
.account-menu {
	background-color: var(--sidebar-bg);

	.page-head {
		background-color: inherit;
	}

	.list-options {
		.link-list {
			.link-list__continue-icon {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}
}

.account-icon-header {
	color: rgba(var(--base-01), 1);
}

// layouts/off-canvas-account

.user-balance {
	.user-balance__title {
		color: rgba(var(--base-07), 1);
	}

	.user-balance__sum {
		color: rgba(var(--base-02), 1);
	}
}

.session-timer {
	color: rgba(var(--base-04), 1);

	&.session-timer--regulation-header {
		color: rgba(var(--base-12), 1);

		.session-timer-time {
			color: inherit;
		}
	}
}

// layouts/payment
// layouts/pusher
// layouts/radio-box

.radio-box {
	background-color: rgba(var(--base-11), 1);

	h5 {
		color: rgba(var(--primary-accent-green), 1);
	}

	p {
		color: rgba(var(--font-light), 1);
	}

	small {
		color: rgba(var(--font-supportive), 1);
	}
}

// layouts/recover-password
// layouts/result

[class^='section-head__SubTitle'],
.u-result-header {
	color: rgba(var(--font-supportive), 1);
}

.result {
	&.result--is-open {
		.page-head {
			border-bottom: 0;
		}

		.btn--secondary {
			background-color: var(--base-09-2);
		}
	}
}

.js-payment-result-information-message {
	.js-try-again-button-header {
		color: rgba(var(--primary-accent-green), 1);
	}

	.js-notify__button-wrapper--failed {
		.btn--secondary:first-child {
			border-radius: var(--button-border-radius);
		}
	}
}

// layouts/search
.adaptable-header {
	.mobile-search-container {
		background-image: var(--search-input-wrapper-mobile-bg) !important;
		background-position: left -44px;
		background-size: 216px 222px;
	}
}

// layouts/shop

.points__svg {
	fill: rgba(var(--primary-accent-green), 1);

	text {
		fill: rgba(var(--base-10), 1);
	}
}

// layouts/signup

.bottom-wrapper {
	background-color: rgba(var(--base-10), 1);

	&.signup--open,
	&.log-in--open {
		background-color: initial;
		border-top: 0;
	}
}

// layouts/sportsbook
// layouts/static-text

.wrapper-about,
.wrapper-responsible-gaming,
.wrapper-privacy_policy,
.wrapper-terms-and-conditions,
.wrapper-cookie-policy {
	background-color: rgba(var(--primary-accent-grey), 1);

	.adaptable-header--pinned {
		--page-head-title: transparent;

		background: rgba(var(--primary-accent-grey), 1);
	}
}

.static-text.terms-conditions {
	.page-head {
		background-color: var(--sidebar-bg);

		&.u-bg-trans {
			background-color: var(--sidebar-bg);
		}
	}
}

// layouts/titles

.disclaimer-text {
	color: rgba(var(--primary-accent-green), 1);
	text-decoration: underline;
}

// layouts/transaction-history

.transaction-modal {
	background-color: rgba(var(--base-12), 1);
}

// layouts/upload

.template-document-wrapper {
	border-bottom: 1px solid rgba(var(--base-08), 1);

	&::after {
		background-color: rgba(var(--primary-accent-green), 1);
	}
}

// layouts/withdrawal

.pending-withdrawal {
	border-bottom: 1px solid rgba(var(--base-08), 1);

	.pending-withdrawal__action {
		.pending-withdrawal__cancel-icon {
			fill: rgba(var(--error), 1);
		}
	}
}

// layouts/SEO content

.seo-content {
	a {
		color: rgba(var(--seo-read-more-btn), 1);
	}
}

// layouts/welcome-screen

.welcome-menu {
	.accordion {
		.accordion__link {
			background-color: rgba(var(--base-12), 1);
		}

		.accordion__content {
			background-color: rgba(var(--base-12), 1);
		}
	}
}

// layouts/wespin

.item--large-we-spin {
	&.tutorial-card {
		p,
		small {
			color: rgba(var(--base-04), 1);
		}

		#tutorial-card-link {
			color: rgba(var(--base-01), 1);
		}
	}

	small span {
		color: rgba(var(--font-supportive), 1);
	}
}

.wrapper-we-spin {
	.transparent_title {
		background-color: transparent;
	}
}

.top-of-page-image-wrapper {
	.fade-image {
		&::after {
			background: linear-gradient(to top, rgba(var(--base-12), 1) 10%, rgba(var(--base-12), 0));
		}
	}

	.wespin-streamelement {
		p,
		small,
		.tag {
			color: rgba(var(--base-01), 1);
		}
	}
}

.wespin-eventlist-date {
	h5 {
		color: rgba(var(--font-light), 1);
	}
}

.wespin-gameroom-footer-prejoin,
.wespin-gameroom-footer-stream-preview {
	border-top: 1px solid rgba(var(--primary-accent-grey-light), 1);

	div {
		h5 > span {
			color: rgba(var(--base-01), 1);
		}

		button {
			span {
				color: rgba(var(--base-12), 1);
			}
		}

		.basic-horizontal-card-wrapper {
			.event-type-title-bg {
				background-color: rgba(var(--base-12), 1);
			}
		}
	}
}

.lobby-card__streamer-name {
	color: rgba(var(--primary-accent-green), 1);
}

/* Streamer profile page */
.streamer-presentation {
	h3 {
		color: rgba(var(--primary-accent-green), 1);
	}

	p {
		color: rgba(var(--base-06), 1);
	}
}

.display-circle {
	background-color: rgba(var(--primary-accent-grey-light), 1);
}

.display-circle--svg {
	background-color: rgba(var(--base-11), 1);

	svg {
		fill: rgba(var(--base-01));
	}
}

.buy-in-link {
	color: rgba(var(--primary-accent-green), 1);
}

.buy-in-link__disabled {
	color: rgba(var(--base-02), 1);
}

.we-spin-edit-stream-modal {
	h3,
	p {
		color: rgba(var(--base-01), 1);
	}

	button {
		color: rgba(var(--base-01), 1);
	}
}

.wespin-tester-create-stream-info {
	color: rgba(var(--base-01), 1);
}

/* Settings */
.upload-file {
	input[type='file'] + label {
		background-color: rgba(var(--base-11), 1);
		border-bottom: 1px solid rgba(var(--base-06), 1);
		color: rgba(var(--primary-accent-green), 1);
	}
}

.new {
	background-color: #a864ff;
	color: rgba(var(--base-01), 1);
}

/* Creating a streamer success page */
.create-streamer-success {
	.page-head__left-side {
		svg {
			fill: rgba(var(--base-01), 1);
		}
	}

	.create-streamer-success-body {
		p,
		h3 {
			color: rgba(var(--base-01), 1);
		}

		svg {
			fill: rgba(var(--base-01), 1);
		}
	}

	footer {
		button {
			background-color: rgba(var(--primary-accent-green-dark), 1);
		}
	}
}

.chevron-round {
	background-color: rgba(var(--primary-accent-green), 1);

	svg {
		fill: rgba(var(--base-01, 1)) !important;
	}
}

.chevron-round__disabled {
	background-color: rgba(var(--base-11), 1);
}

.filters-container,
.wespin-sidebar {
	.basic-horizontal-card-wrapper {
		background-color: rgba(var(--base-11), 1);
	}
}

.wespin-ended-event-card {
	.basic-horizontal-card-wrapper small {
		color: rgba(var(--base-08), 1);
	}
}

.wespin-social-share {
	button.btn--secondary {
		background-color: rgba(var(--base-11), 1);
	}
}

.wespin-buyin-form,
.wespin-gameroom-preview {
	.collapse__info-icon,
	.collapse__continue-icon {
		fill: rgba(var(--primary-accent-green), 1);
	}

	.we-spin-game-room__collapse {
		margin-top: 16px;
	}
}

.wespin-large-buyin-button-container {
	border-top: 1px solid rgba(var(--primary-accent-grey-light), 1);
}

.game-view {
	h5 {
		color: rgba(var(--base-01), 1);
	}

	svg {
		fill: rgba(var(--base-01), 1);
	}
}

.wespin-gameroom-preview > .info-box.edit-buy-in > .edit-buyin-button,
.buy-in-buttons div .btn {
	border: 1px solid rgba(var(--primary-accent-green), 1);

	&.btn--small {
		background: rgba(var(--primary-accent-green), 1);
		color: rgba(var(--font-dark), 1);
	}

	&.btn--small.edit-buyin-button,
	&.btn--light {
		background-color: rgba(var(--base-12), 1);
		color: rgba(var(--primary-accent-green), 1);

		&:hover {
			@media (hover: hover) {
				background: rgba(var(--primary-accent-green), 1);
				color: rgba(var(--font-dark), 1);
			}
		}
	}
}

.avatar-circle {
	border: 2px solid rgba(var(--primary-accent-grey-light), 1);

	.avatar-circle__flag {
		border: 2px solid rgba(var(--primary-accent-grey-light), 1);
	}
}

.create-events-button,
.wespin-upload-file--button {
	background-color: transparent;
	border: 1px solid rgba(var(--primary-accent-green), 1);
	color: rgba(var(--primary-accent-green), 1);

	&:hover {
		@media (hover: hover) {
			border: 1px solid rgba(var(--primary-accent-green-light), 1);
			color: rgba(var(--primary-accent-green-light), 1);
		}
	}
}

.BONUS_HUNT {
	background-image: radial-gradient(circle, rgba(128, 79, 193, 100%) 30%, rgba(220, 22, 199, 100%) 100%);
}

.BONUS_BUY {
	background-image: radial-gradient(circle, rgba(128, 79, 193, 100%) 30%, rgba(220, 22, 199, 100%) 100%);
}

.JACKPOT_HUNT {
	background-image: radial-gradient(circle, rgba(231, 242, 51, 100%) 30%, rgba(132, 233, 34, 100%) 100%);
}

.STREAMERS_CHOICE {
	background-image: linear-gradient(to right, rgb(0, 122, 173) 0%, rgb(165, 223, 249) 50%, rgb(0, 122, 173) 100%);
}

// Wespin EventTypeTitle BG color
.event-type-title-bg {
	background: rgba(var(--base-12), 1);
}

.top-of-page-image-wrapper .fade-image .event-type-title-bg.event-type-transparent .event-type-title-text {
	line-height: 70px;
}

// utils/placeholders
// utils/utility-classes

.u-box-bg {
	background-color: rgba(var(--base-10), 1);
}

.u-text-grey {
	color: rgba(var(--base-07), 1);
}

.u-divider {
	border-color: rgba(var(--base-08), 1);
}

.adaptable-header--unfixed {
	background: var(--brandContainerBackground);
	background-size: 216px 222px;
}

.wespin-game-room-eventlist-upcoming-events {
	.basic-horizontal-card-wrapper {
		div[disabled] {
			&.basic-horizontal-card--top-text {
				small {
					color: rgba(var(--font-supportive), 1);
				}
			}

			&.basic-horizontal-card--middle-text {
				h5 {
					color: rgba(var(--base-01), 1);
				}
			}
		}
	}
}

.payment-tag {
	&__popular {
		background-color: rgba(var(--primary-accent-green), 1);
	}

	&__no-fee {
		background-color: rgba(var(--primary-accent-pink), 1);
	}
}

// Responsible Gaming

.my-limits-menu {
	.collapse-value {
		color: rgba(var(--font-supportive), 1);
	}
}

// Transaction History
.selected-filter-tags {
	background-color: rgba(var(--base-12), 1) !important;

	span.selected-filter-tags_text {
		color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
	}

	button::after {
		color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
	}
}

.pill.disable span {
	color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
}

p.circle-progress-bar {
	font: var(--caption-bold);
}
