:root {
	--sportsbook-bg-primary: rgba(var(--base-10), 1);
	--sportsbook-bg-secondary: rgba(var(--base-11), 1);
	--sportsbook-bg-tertiary: rgba(var(--base-10), 1);
	--sportsbook-border-primary: rgba(var(--primary-accent-grey), 1);

	// Sport page
	--sportsbook-inline-date-picker-title-disabled-color: var(--sportsbook-text-supportive);

	//	// Betslip
	--sportsbook-betslip-bg-color: var(--sportsbook-bg-secondary);
	--sportsbook-betslip-header-bets-count-circle-color: rgba(var(--primary-accent-green), 1);
	--sportsbook-minimized-betslip-header-bets-count-circle-color: rgba(var(--font-dark), 1);
	--sportsbook-minimized-betslip-header-title-color: rgba(var(--font-dark), 1);
	--sportsbook-minimized-betslip-header-icon-color: rgba(var(--font-dark), 1);
	--sportsbook-betslip-header-bets-count-label-color: rgba(var(--font-dark), 1);
	--sportsbook-minimized-betslip-header-bets-count-label-color: rgba(var(--font-light), 1);
	--sportsbook-betslip-radio-button-checked-color: rgba(var(--primary-accent-green), 1);
	--sportsbook-betslip-header-icon-color: rgba(var(--primary-accent-green), 1);
	--sportsbook-is-prominent-color: rgba(var(--primary-accent-green), 1);
	--sportsbook-share-betslip-copy-input-bg: rgba(var(--base-10), 1);
	--sportsbook-betslip-message-text-color: rgba(var(--base-02), 1);
	--sportsbook-betslip-message-action-button-text-color: rgba(var(--base-02), 1);
	--sportsbook-betslip-border: rgba(var(--primary-accent-grey), 1);
	--sportsbook-navigation-tab-disabled-color: rgba(var(--base-09), 1);
	--sportsbook-betslip-freebet-select-bg: var(--sportsbook-bg-secondary);
	--sportsbook-betslip-system-bets-title-bg: rgba(var(--base-11), 1);
	--sportsbook-betslip-icon-color: var(--sportsbook-icon-primary);
	--sportsbook-betslip-spinner: rgba(var(--base-01), 1);

	// Selection Button
	--selection-button-active-text-color: rgba(var(--font-dark), 1);

	// My bets
	--sportsbook-my-bets-share-button-bg: rgba(var(--primary-accent-pink), 1);

	// Event page
	--sportsbook-event-statistics-icon-color: rgba(var(--primary-accent-green), 1);
	--sportsbook-event-dropdown-icon-color: rgba(var(--sportsbook-icon-primary), 1);

	// Event Widget
	--sportsbook-event-widget-button-color: rgba(var(--base-08), 1);

	// Carousel
	--sportsbook-carousel-promotion-combo-button-bg-color: rgba(var(--primary-accent-green), 1);
	--sportsbook-carousel-promotion-combo-button-text-color: rgba(var(--font-light), 1);
	--sportsbook-carousel-promotion-combo-button-bg-color-hover: var(--button-primary-bg-hover);
	--sportsbook-carousel-promotion-combo-button-text-color-hover: rgba(var(--font-light), 1);

	// Recommended cards
	--sportsbook-recommended-card-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-recommended-card-bg-gradient-top-color: rgba(var(--primary), 0.15);
	--sportsbook-recommended-card-bg-gradient-bottom-color: rgba(var(--primary-accent-grey-dark), 0.15);
	--sportsbook-recommended-card-header-bg-gradient-left-color: rgba(var(--primary-accent-grey-dark), 0.5);
	--sportsbook-recommended-card-header-bg-gradient-right-color: rgba(var(--primary-accent-grey-dark), 0);
	--sportsbook-recommended-card-border-color: rgba(var(--base-01), 0.05);
	--sportsbook-recommended-card-icon-bg-color: rgba(var(--primary), 1);
	--sportsbook-recommended-card-icon-color: var(--sportsbook-bg-primary);

	// Other
	--sportsbook-live-color: rgba(var(--primary-accent-pink), 1);
	--sportsbook-live-text-color: rgba(var(--base-02), 1);
	--sportsbook-event-status-indicator-text-color: rgba(var(--base-02), 1);
	--sportsbook-navigation-tab-border-color: var(--sportsbook-border-primary);
	--sportsbook-betslip-placement-interaction-text-error-color: rgba(var(--font-light), 1);
	--sportsbook-browse-link-icon-color: rgba(var(--primary-accent-green), 1);
	--sportsbook-my-bets-expand-color: var(--base-10);
	--sportsbook-top-bar-bg-color: var(--base-11);
	--sportsbook-search-filter-pill-bg-color: var(--sportsbook-bg-secondary);
	--sportsbook-explore-featured-link-text-color: rgba(var(--primary-accent-green));
}
